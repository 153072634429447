import React from "react";
import './Project.scss';

class Project extends React.Component {
  render() {
    // Material design icon in bootstrap button fix: https://github.com/google/material-design-icons/issues/206
    let linkButton = null;
    if (this.props.linkUrl) {
      if (this.props.linkUrl.startsWith('http')) {
        linkButton =
          <a href={this.props.linkUrl} className="btn btn-primary" target="_blank" rel="noopener noreferrer">
            {this.props.linkText}
            &nbsp;
            <i className="material-icons d-inline-flex align-middle">open_in_new</i>
          </a>
      } else {
        linkButton = <a href={this.props.linkUrl} className="btn btn-primary">{this.props.linkText}</a>
      }
    }

    return (
      <div className="card project-card mb-4">
        <div className="row no-gutters">
          {this.props.image &&
          <div className="col-lg-4 text-center my-auto">
            <img src={this.props.image} className="card-img project-img" alt="project"/>
          </div>
          }
          <div className="col-lg">
            <div className="card-body">
              <h5 className="card-title">{this.props.title}</h5>
              <h6 className="card-subtitle mb-2 text-muted">{this.props.date.toDate().toLocaleDateString()}</h6>
              <p className="card-text">
                {this.props.description}
              </p>
              {this.props.tags &&
              <p className="card-text">
                {this.props.tags}
              </p>
              }
              {linkButton}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Project;