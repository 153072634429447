import React from "react";

class Test extends React.Component {
  render() {
    return (
      <main role="main" className="container my-5">
        test
      </main>
    );
  }
}

export default Test;