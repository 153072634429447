import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/analytics';

const firebaseConfig = {
  apiKey: "AIzaSyC5KMQzq55N2ylyT_pMpVB9wToApjA5tF0",
  authDomain: "julestalloen.firebaseapp.com",
  databaseURL: "https://julestalloen.firebaseio.com",
  projectId: "julestalloen",
  storageBucket: "julestalloen.appspot.com",
  messagingSenderId: "779984740926",
  appId: "1:779984740926:web:8a3c3ae68120402a84e76f",
  measurementId: "G-55085G94DF"
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();
const db = firebase.firestore();

export {db};
