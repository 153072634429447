import React from "react";

class RouteNotFound extends React.Component {
  render() {
    return (
      <main role="main" className="container my-5">
        <h3>Page <code>{this.props.location.pathname}</code> not found.</h3>
      </main>
    );
  }
}

export default RouteNotFound;