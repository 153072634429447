import React from "react";

class Footer extends React.Component {
  render() {
    return (
      <footer className="footer py-3 bg-light">
        <div className="container">
          <span className="text-muted">&copy; Jules Talloen</span>
        </div>
      </footer>
    )
  }
}

export default Footer;