import './Home.scss';
import linkedin_logo from "../assets/linkedin_logo.png";
import React from "react";
import jules from "../assets/jules.png";
import Project from "../partials/Project";
import {db} from "../utils/firebase";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      projects: [],
      tags: [],
      loading: true
    };

    this.fetchTags = this.fetchTags.bind(this);
    this.fetchDocuments = this.fetchDocuments.bind(this);
    this.compareTags = this.compareTags.bind(this);
  }

  compareTags(a, b) {
    let a_name = this.state.tags[a.path].data().name;
    let b_name = this.state.tags[b.path].data().name;

    return a_name.localeCompare(b_name)
  }

  docToProject(doc) {
    let data = doc.data();

    // Fetch the documents tags from state, sort and convert to Tag components
    let tags = null;
    if (data.tags) {
      let tagRefs = data.tags.sort(this.compareTags);
      tags = tagRefs.map(tagRef => {
        return this.docToTag(this.state.tags[tagRef.path]);
      });
    }

    return <Project key={doc.id}
                    image={data.image}
                    title={data.title}
                    date={data.date}
                    description={data.description}
                    linkText={data.link_text}
                    linkUrl={data.link_url}
                    tags={tags}
    />
  }

  docToTag(doc) {
    return (
      <React.Fragment key={doc.id}>
        <span className="badge badge-secondary">{doc.data().name}</span>{' '}
      </React.Fragment>
    )
  }

  fetchTags() {
    const component = this;
    db.collection('tags').get()
      .then(snapshot => {
        if (snapshot.empty) {
          console.log('No matching documents.');
          return;
        }
        component.setState({
          tags: snapshot.docs.reduce(function (map, obj) {
            map[obj.ref.path] = obj;
            return map;
          }, {})
        })
      })
      .catch(err => {
        console.log('Error getting documents', err);
      });
  }

  fetchDocuments() {
    const component = this;
    db.collection('projects')
      .where('visible', '==', true)
      .orderBy("date", "desc")
      .get()
      .then(snapshot => {
        if (snapshot.empty) {
          console.log('No matching documents.');
          return;
        }
        component.setState({
          projects: snapshot.docs.map(doc => this.docToProject(doc)),
          loading: false
        })
      })
      .catch(err => {
        console.log('Error getting documents', err);
      });
  }

  componentDidMount() {
    this.fetchTags();
    this.fetchDocuments();
  }

  render() {
    return (
      <main role="main" className="container my-5">
        <section id="personal" className="text-center">
          <img src={jules} className="personal shadow-sm" alt="logo"/>
          <h1 className="my-3 font-weight-light">Jules Talloen</h1>
          <a href="https://www.linkedin.com/in/jules-talloen/">
            <img src={linkedin_logo} className="social" alt="LinkedIn"/>
          </a>
        </section>
        {/*<section id="tags" className="mt-5">*/}
        {/*  {Object.keys(this.state.tags).length > 0 &&*/}
        {/*    Object.keys(this.state.tags).map(path => this.docToTag(this.state.tags[path]))}*/}
        {/*</section>*/}
        {this.state.loading ?
          <div className="d-flex justify-content-center">
            <div className="spinner-border text-primary m-5" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
          :
          this.state.projects.length > 0 &&
          <section id="projects" className="mt-5">
            <h1>Projects</h1>
            {this.state.projects}
          </section>
        }
      </main>
    );
  }
}

export default Home;