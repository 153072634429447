import React from "react";
import "./PoseTransfer.scss";
import axios from 'axios';
import * as d3 from "d3";
import default_graph from "../assets/json/graph.json";

class PoseTransfer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 176,
      height: 256,
      scale: 1.5,
      graph: JSON.parse(JSON.stringify(default_graph)),
    }

    this.drawPose = this.drawPose.bind(this);
    this.reset = this.reset.bind(this);
  }

  drawPose() {
    let component = this;

    const svg = d3.select("svg#pose")
      .attr("width", this.state.scale * this.state.width)
      .attr("height", this.state.scale * this.state.height)
      .attr("border", 1)
      .attr("viewBox", `0 0 ${this.state.width} ${this.state.height}`);

    svg.selectAll("*").remove();

    svg.append("rect")
      .attr("x", 0)
      .attr("y", 0)
      .attr("width", this.state.width)
      .attr("height", this.state.height)
      .style("stroke", "black")
      .style("fill", "none")
      .style("stroke-width", 1);

    this.state.graph.links.forEach(function (d) {
      d.source = component.state.graph.nodes[d.source];
      d.target = component.state.graph.nodes[d.target];
    });

    let link = svg.append("g")
      .attr("class", "link")
      .selectAll("line")
      .data(component.state.graph.links)
      .enter().append("line")
      .attr("x1", function (d) {
        return d.source.x;
      })
      .attr("y1", function (d) {
        return d.source.y;
      })
      .attr("x2", function (d) {
        return d.target.x;
      })
      .attr("y2", function (d) {
        return d.target.y;
      })
      .attr("stroke-width", 3)
      .attr("stroke", function (d) {
        if (d.color) {
          return d3.rgb(...d.color);
        } else {
          return "black";
        }
      });

    svg.append("g")
      .attr("class", "node")
      .selectAll("circle")
      .data(component.state.graph.nodes)
      .enter().append("circle")
      .attr("r", 3)
      .attr("cx", function (d) {
        return d.x;
      })
      .attr("cy", function (d) {
        return d.y;
      })
      .call(d3.drag().on("drag", dragged));

    function dragged(d) {
      d.x = d3.event.x
      d.y = d3.event.y;
      d3.select(this).attr("cx", d.x).attr("cy", d.y);
      link.filter(function (l) {
        return l.source === d;
      }).attr("x1", d.x).attr("y1", d.y);
      link.filter(function (l) {
        return l.target === d;
      }).attr("x2", d.x).attr("y2", d.y);
    }
  }

  reset() {
    this.setState({graph: JSON.parse(JSON.stringify(default_graph))});
    console.log("Reset graph")
  }

  save() {
    console.log("Saving graph: TODO");

    axios.get("https://api-mwpvr3bwjq-ew.a.run.app")
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      })
  }

  componentDidMount() {
    this.drawPose();
  }

  componentDidUpdate() {
    this.drawPose();
  }

  render() {
    return (
      <main role="main" className="container my-5">
        <h1 className="mb-3">Pose transfer</h1>
        <div className="row">
          <div className="col-sm mb-3" id="pose">
            <svg id="pose"/>
          </div>
          <div className="col-sm" id="controls">
            <p>Click and hold the keypoints to move them.</p>
            <button type="button" className="btn btn-primary mr-2 mb-2" onClick={this.reset}>Reset keypoints</button>
            <button type="button" className="btn btn-success mb-2" onClick={this.save}>Save keypoints</button>
          </div>
        </div>
      </main>
    );
  }
}

export default PoseTransfer;