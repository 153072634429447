import React from 'react';
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
// import Header from "./base/Header"
import Footer from "./base/Footer"
import Home from "./pages/Home";
import Test from "./pages/Test";
import ErrorBoundary from "./partials/ErrorBoundary";
import PoseTransfer from "./pages/PoseTransfer";
import RouteNotFound from "./pages/RouteNotFound";

class App extends React.Component {
  render() {
    const excludeFooter = ["/landing"].indexOf(window.location.pathname) !== -1;

    return (
      <Router>
        {/*<Header/>*/}
        <div className="flex-grow-1">
          <ErrorBoundary>
            <Switch>
              <Route exact path="/" component={Home}/>
              <Route path="/pose_transfer" component={PoseTransfer}/>
              <Route path="/test" component={Test}/>
              <Route component={RouteNotFound} />
            </Switch>
          </ErrorBoundary>
        </div>
        {!excludeFooter && (<Footer/>)}
      </Router>
    );
  }
}

export default App;
